/* eslint-disable react/no-danger */
import React, {
	Suspense,
	Fragment,
	useState,
	useCallback,
	useEffect,
	useRef
} from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { Common } from 'ws-scripts/modules/common';
import { isBrowser } from 'ws-scripts/modules/environment';
import { getQueryStringValue } from 'wsm-srp-utilities';
import { trackEvent } from 'ddc-track-event';
import { setClassNames } from 'ddc-classnames-js';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import useFreeFormSearchServiceApi from '../hooks/useFreeFormSearchServiceApi';
import ErrorBoundary from './ErrorBoundary';
import { applyFilters, getFilterParams } from '../utils/submitSearchUtils';

const SearchResults = React.lazy(() =>
	import(
		/* webpackChunkName: "SearchResults" , webpackPrefetch: true */ './SearchResults'
	)
);

const Form = () => {
	const formRef = useRef();
	const inputRef = useRef();
	const { widgetName, windowId, deviceType } = useSelector(
		(state) => state.requestData
	);
	const contextEnabled = useSelector(
		(state) => state.flags['ws-inv-search-context-enabled']
	);
	const spellcheckEnabledFlag = useSelector(
		(state) => state.flags['ws-inv-data-fetch-spellcheck']
	);
	const searchParamValue = getQueryStringValue(window.location.href, 'search')
		? decodeURIComponent(
				getQueryStringValue(window.location.href, 'search')
		  )
		: '';
	const [input, setInput] = useState(searchParamValue);
	const [debouncedIn, setDebouncedIn] = useState(input);
	const [activeResultIndex, setActiveResultIndex] = useState(-1);
	const [interacted, setInteracted] = useState(false);

	const debouncedSet = useCallback(
		window._.debounce(setDebouncedIn, 250),
		[]
	);
	const [response, error] = useFreeFormSearchServiceApi(
		debouncedIn,
		contextEnabled
	);

	const suggestions =
		response && response.suggestions ? response.suggestions : [];
	const [isFocused, setIsFocused] = useState(false); // Polyfill :focus-within by using 'in' class
	const [showModal, setShowModal] = useState(false);
	const [formHeight, setFormHeight] = useState(null);
	const [resultsMaxHeight, setResultsMaxHeight] = useState(null);
	const currentSearch = {
		suggestion: input,
		facetInfo: {
			search: input
		}
	};
	const isMobile = deviceType === 'MOBILE';
	const windowHeight = window.innerHeight;
	const formTop = formRef.current
		? formRef.current.getBoundingClientRect().top
		: 0;
	const modalPadding = 32;
	const maxModalHeight = 470;
	// If input field falls within lower half of window OR there is less than max modal height below input field,
	// auto scroll window on form focus so that input field falls within top quarter of window.
	const scrollBy =
		windowHeight - formTop < maxModalHeight || formTop > windowHeight / 2
			? formTop - windowHeight / 4
			: 0;
	const appliedFilters =
		isBrowser && window.DDC?.InvData?.getAppliedFilters
			? window.DDC?.InvData?.getAppliedFilters()
			: [];
	let selectedKey = activeResultIndex;
	const userAgent = window.navigator?.userAgent;
	const isAndroid = userAgent.match(/Android/i) !== null;

	useEffect(() => {
		if (!showModal && isFocused) {
			setShowModal(true);

			trackEvent(widgetName, windowId, {
				action: 'clicked',
				element: 'search box',
				result: 'search modal initiated'
			});

			window.scrollBy({ top: scrollBy, behavior: 'smooth' });
		}
	}, [showModal, isFocused, scrollBy]);

	useEffect(() => {
		if (formRef.current) {
			if (!showModal) {
				setFormHeight(formRef.current.clientHeight);
			} else {
				const newFormTop = windowHeight / 4;
				setResultsMaxHeight(
					windowHeight - newFormTop - formHeight - modalPadding
				);
			}
		}
	}, [formRef, showModal, windowHeight, formHeight]);

	const handleClear = () => {
		setInput('');
		setDebouncedIn('');
		setActiveResultIndex(-1);
	};
	const handleFocus = () => {
		if (!isMobile || isAndroid) setIsFocused(true);
	};
	const handleClick = () => {
		if (isMobile && !isFocused && !isAndroid) setIsFocused(true);
	};
	const handleBlur = () => {
		setIsFocused(false);
		setActiveResultIndex(-1);
	};
	const handleSubmit = (e, SO) => {
		if (input) {
			const facetInfo = SO && SO.facetInfo;
			let eventType = 'result';
			if (e.target.type === 'submit') {
				eventType = 'enter';
			}
			if (e.target.id === 'search-icon') {
				eventType = 'icon click';
			}
			const numResults =
				(response &&
					response.suggestions &&
					response.suggestions.length) ||
				0;
			setNewRelicCustomAttribute('wsInvTextSearchInput', input);
			setNewRelicCustomAttribute(
				'wsInvTextSearchInputEventType',
				eventType
			);
			setNewRelicCustomAttribute(
				'wsInvTextSearchInputNumResults',
				numResults
			);
			setNewRelicCustomAttribute(
				'wsInvTextSearchInputAppliedFilters',
				getFilterParams(appliedFilters)
			);

			trackEvent(widgetName, windowId, {
				action: 'submitted',
				element: 'search form',
				fieldValue: SO.suggestion,
				result: 'form submitted'
			});
			applyFilters(e, facetInfo, contextEnabled, spellcheckEnabledFlag);
			setInput('');
			setDebouncedIn('');
			setIsFocused(false);
			setShowModal(false);
		}
	};

	const onArrowKey = (direction) => {
		switch (direction) {
			case 'up':
				selectedKey -= 1;
				if (selectedKey < 0) selectedKey = -1;
				setActiveResultIndex(selectedKey);
				break;
			case 'down':
				selectedKey += 1;
				if (selectedKey > suggestions.length - 1) selectedKey = -1;
				setActiveResultIndex(selectedKey);
				break;
			default:
				break;
		}
	};

	const handleKeyUp = (e) => {
		if (e.key === 'Escape') {
			handleClear();
			setIsFocused(false);
			setShowModal(false);
		}
		if (e.keyCode === 38) {
			onArrowKey('up');
			e.preventDefault();
		} else if (e.keyCode === 40) {
			onArrowKey('down');
			e.preventDefault();
		}
	};

	const handleKeyDown = (event) => {
		if (!interacted) {
			setInteracted(true);
			trackEvent(widgetName, windowId, {
				// the rest of the payload gets added by trackEvent function (src/index.js)
				element: 'search box',
				result: 'search modal initiated'
			});
		}
		if (event.keyCode === 38 || event.keyCode === 40) {
			event.preventDefault(); // Prevent the cursor from moving within the input
		}
	};

	const handleActiveResultIndex = (index) => {
		setActiveResultIndex(index);
	};

	const handleInput = (e) => {
		const newInput = e.target.value;
		if (newInput !== input) {
			setActiveResultIndex(-1);
			setIsFocused(true);
			setInput(newInput);
			debouncedSet(newInput);
		}
	};

	const onOpen = () => {
		if (inputRef && inputRef.current) {
			inputRef.current.focus();
			// This is required to force the input field to be in view when the modal opens
			// It shouldn't be needed, but there is an issue with the interaction between
			// the modal opening and the soft iOS keyboard pushing the input out of view
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				window.scrollTo({
					top: inputRef.current.offsetTop
				});
			}
		}
	};

	const formClasses = setClassNames([
		'form-control',
		'd-flex',
		'p-0',
		'h-auto',
		isFocused ? 'in' : null
	]);

	return (
		<Common
			render={({ labels }) => {
				const form = (
					<form
						ref={formRef}
						className={setClassNames([
							'free-text-search-form',
							'w-100',
							'p-0',
							isMobile
								? null
								: `${formClasses} flex-column absolute-top`
						])}
						autoComplete="off"
						style={{
							zIndex: !isMobile && showModal ? 2147483647 : null
						}}
					>
						<div
							className={setClassNames([
								isMobile ? formClasses : 'd-flex w-100'
							])}
						>
							<input
								ref={inputRef}
								className={setClassNames([
									'free-text-search',
									'form-control',
									'form-control-transparent',
									isMobile
										? 'font-size-ios-zoom-override'
										: 'input-lg'
								])}
								id="free-text-search-input"
								type="search"
								name="search"
								placeholder={labels.get(
									'SEARCH_OUR_INVENTORY_ELLIPSIS'
								)}
								aria-label={labels.get(
									'SEARCH_OUR_INVENTORY_ELLIPSIS'
								)}
								onChange={handleInput}
								onKeyUp={handleKeyUp}
								onKeyDown={handleKeyDown}
								value={input}
								onFocus={handleFocus}
								onBlur={handleBlur}
								onClick={handleClick}
							/>
							<span className="free-text-search-controls d-flex">
								{input !== '' && showModal && (
									<button
										className="text-link text-link-muted px-2 mt-0 ddc-font-size-xsmall"
										onClick={(e) => {
											e.preventDefault();
											trackEvent(widgetName, windowId, {
												element: 'clear button',
												result: 'search field cleared'
											});
											handleClear();
											inputRef.current.focus();
										}}
										type="button"
									>
										{labels.get('CLEAR')}
									</button>
								)}
								<button
									className={setClassNames([
										'text-link',
										'px-4',
										'mt-0',
										'text-no-decoration',
										`icon-size-${isMobile ? 2 : 3}`
									])}
									type="submit"
									onClick={(e) => {
										trackEvent(widgetName, windowId, {
											element: 'submit button',
											result: 'form submitted',
											widgetValue: input
										});
										handleSubmit(
											e,
											activeResultIndex < 0
												? currentSearch
												: suggestions[activeResultIndex]
										);
									}}
									disabled={input === ''}
									title={labels.get('SEARCH')}
									aria-label={labels.get('SEARCH')}
								>
									<i
										id="search-icon"
										className="ddc-icon ddc-icon-search"
										aria-hidden="true"
									/>
								</button>
							</span>
						</div>
						{!isMobile && showModal && (
							<ErrorBoundary>
								<Suspense fallback={<div />}>
									<SearchResults
										results={response}
										display={showModal}
										submitSearch={handleSubmit}
										hasError={!!error}
										{...{
											input,
											resultsMaxHeight,
											contextEnabled,
											appliedFilters
										}}
										activeResultIndex={activeResultIndex}
										setActiveResultIndex={
											handleActiveResultIndex
										}
									/>
								</Suspense>
							</ErrorBoundary>
						)}
					</form>
				);

				return isMobile ? (
					// Mobile display renders form, close button and search results within a modal.
					<Fragment>
						{form}
						<Modal
							restoreFocus={false}
							show={showModal}
							onHide={() => setShowModal(false)}
							onShow={onOpen}
							className="dock-full no-slide"
						>
							<Modal.Header className="border-bottom-0 pb-0 d-flex justify-content-end">
								<Modal.Title
									id="site-text-search-modal-title"
									className="sr-only"
								>
									{labels.get(
										'SEARCH_OUR_INVENTORY_ELLIPSIS'
									)}
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<div className="d-flex">
									{form}
									<button
										className="btn-unstyled px-4"
										type="button"
										onClick={() => {
											trackEvent(widgetName, windowId, {
												element: 'close button',
												result: 'search modal closed'
											});
											handleClear();
											setIsFocused(false);
											setShowModal(false);
										}}
										aria-label={labels.get('CLOSE')}
									>
										<i
											className="ddc-icon ddc-icon-remove2 icon-size-2"
											aria-hidden="true"
										/>
									</button>
								</div>
								<ErrorBoundary>
									{showModal && (
										<Suspense fallback={<div />}>
											<SearchResults
												results={response}
												display={showModal}
												submitSearch={handleSubmit}
												hasError={!!error}
												{...{
													input,
													contextEnabled,
													appliedFilters
												}}
											/>
										</Suspense>
									)}
								</ErrorBoundary>
							</Modal.Body>
						</Modal>
					</Fragment>
				) : (
					// Desktop/Tablet display renders form and simulates a modal display on focus.
					// The form stays inline within context of the document to not display reflow
					// of page content to user. The Modal component is used to render a backdrop
					// and remove scroll, but does not render as a traditional modal.
					<Fragment>
						<div
							className="position-relative"
							style={{ height: formHeight }}
						>
							{form}
						</div>
						{showModal && (
							<Suspense fallback={<div />}>
								<Modal
									animation={false}
									restoreFocus={false}
									show={showModal}
									onShow={() => inputRef.current.focus()}
									onHide={() => {
										trackEvent(widgetName, windowId, {
											element: 'background overlay',
											result: 'search modal closed'
										});
										setShowModal(false);
									}}
									// Disabled Bootstrap modal markup from displaying onShow
									dialogComponentClass={() => null}
								/>
							</Suspense>
						)}
					</Fragment>
				);
			}}
		/>
	);
};

export default Form;
