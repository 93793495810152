import React from 'react';
import { connect } from 'react-redux';
import { isBrowser } from 'ws-scripts/modules/environment';
import logger from 'ws-scripts/modules/logger';
import { ErrorBoundary } from 'wsm-error-boundary';
import { setNewRelicCustomAttribute } from 'ddc-new-relic';
import { waitForInvData } from 'wsm-srp-utilities';
import Skeleton from 'wsm-skeleton';
import PropTypes from 'prop-types';
import Form from '../components/Form';

class WidgetContainer extends React.Component {
	constructor(props) {
		super(props);
		if (isBrowser) {
			waitForInvData(this);
		}
	}

	render() {
		const { search, serverRender } = this.props?.widgetInfo;
		const isMobile = this.props.deviceType === 'MOBILE';

		if (!isBrowser || !window?.DDC?.InvData) {
			// Using skeleton render if SSR is disable
			if (!serverRender) {
				return (
					<Skeleton
						skeletonClasses="form-control border-0"
						height={isMobile ? '' : '61px'}
					/>
				);
			}

			const formClasses = 'form-control d-flex h-auto p-0';
			const formContent = (
				<form
					className={`free-text-search-form w-100 p-0 ${
						isMobile ? '' : formClasses
					} flex-column`}
				>
					<div className={isMobile ? formClasses : 'd-flex w-100'}>
						<input
							className={`free-text-search form-control form-control-transparent
								${isMobile ? 'font-size-ios-zoom-override' : 'input-lg'}`}
							id="free-text-search-input"
							type="search"
							name="search"
							placeholder="Search our inventory…"
							defaultValue={
								search ? decodeURIComponent(search) : ''
							}
						/>
						<span className="free-text-search-controls d-flex">
							<button
								className={`text-link px-4 mt-0 text-no-decoration icon-size-${
									isMobile ? 2 : 3
								} `}
								type="submit"
								disabled
								title="Search"
								aria-label="Search"
							>
								<i
									id="search-icon"
									className="ddc-icon ddc-icon-search"
								/>
							</button>
						</span>
					</div>
				</form>
			);

			return isMobile ? (
				formContent
			) : (
				<div className="position-relative">{formContent}</div>
			);
		}

		return (
			<ErrorBoundary
				errorHandler={(error, errorInfo) => {
					setNewRelicCustomAttribute(
						'SRP ERROR',
						`ws-inv-text-search error boundary.\n${error}`
					);
					const newError = new Error(
						`ws-inv-text-search error boundary.\n${error}`
					);
					newError.originalError = error;
					newError.originalStackTrace = errorInfo.componentStack;
					logger.error(`${newError}\n${newError.originalStackTrace}`);
				}}
			>
				<Form />
			</ErrorBoundary>
		);
	}
}

const mapStateToProps = (_state) => ({
	widgetInfo: _state.widgetInfo,
	deviceType: _state.requestData.deviceType
});

const mapDispatchToProps = (_dispatch) => ({});

WidgetContainer.propTypes = {
	widgetInfo: PropTypes.shape({
		search: PropTypes.string,
		serverRender: PropTypes.bool
	}),
	deviceType: PropTypes.string
};

const Widget = connect(mapStateToProps, mapDispatchToProps)(WidgetContainer);

export default Widget;
