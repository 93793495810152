import { useEffect, useState } from 'react';
import {
	addNewRelicPageAction,
	setNewRelicCustomAttribute
} from 'ddc-new-relic';
import transformFFSSResponse from '../utils/transformFFSSResponse';
import { getFilterParams } from '../utils/submitSearchUtils';

// TODO use Suspense instead of hook to fetch data, when Suspense is ready
// https://reactjs.org/docs/concurrent-mode-suspense.html
const useFreeFormSearchServiceApi = (input, contextEnabled) => {
	const apiURL = window.DDC.InvData.SEARCH_API_URL;
	const { origin } = window.location;
	const [response, setResponse] = useState(null);
	const [fetchError, setError] = useState(null);

	useEffect(() => {
		if (input) {
			(async () => {
				let filterParams = '';
				if (contextEnabled) {
					filterParams = getFilterParams(
						window.DDC.InvData.getAppliedFilters()
					);
					if (filterParams) {
						filterParams = filterParams.concat('&');
					}
				}
				const search = `search=${encodeURIComponent(input)}`;
				const searchUrl = `${origin}${apiURL}?${filterParams}${search}`;
				const startTime = Date.now();
				try {
					const searchResults = await fetch(searchUrl);
					setNewRelicCustomAttribute('wsInvTextSearchInput', input);
					if (searchResults.ok) {
						const result = await searchResults.json();
						addNewRelicPageAction(
							'WS INV TEXT SEARCH FETCH SUCCESS',
							{
								requestUrl: searchUrl,
								requestDuration: Date.now() - startTime,
								responseLength: JSON.stringify(result).length,
								responseStatus: searchResults.statusText,
								statusCode: searchResults.status
							}
						);
						setNewRelicCustomAttribute(
							'wsInvTextSearchInputNumResults',
							result.suggestions[0].count > 0
								? result.suggestions.length
								: 0
						);
						setResponse(transformFFSSResponse(result));
						setError(null);
					} else {
						throw Object.assign(
							new Error('Error fetching search suggestions'),
							{
								responseStatus: searchResults.statusText,
								statusCode: searchResults.status
							}
						);
					}
				} catch (err) {
					addNewRelicPageAction('WS INV TEXT SEARCH FETCH ERROR', {
						requestUrl: searchUrl,
						requestDuration: Date.now() - startTime,
						responseStatus: err.responseStatus,
						statusCode: err.statusCode
					});
					setError(err);
				}
			})();
		} else {
			setResponse({});
		}
	}, [apiURL, origin, input, contextEnabled]);
	return [response, fetchError];
};

export default useFreeFormSearchServiceApi;
