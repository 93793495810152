const transformFFSSResponse = (response) => {
	const e1 = response[0];
	// add quotes around 'search=...' suggestion
	if (e1 && e1.facetInfo && Object.keys(e1.facetInfo)[0] === 'search') {
		response[0].suggestion = `"${Object.values(e1.facetInfo)[0]}"`;
	}
	return response;
};

export default transformFFSSResponse;
