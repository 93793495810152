import React from 'react';
import PropTypes from 'prop-types';
import { addNewRelicPageAction } from 'ddc-new-relic';

export default class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false, error: null };
	}

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI
		return { hasError: true, error };
	}

	componentDidCatch(error, info) {
		// Log error to New Relic
		addNewRelicPageAction('WS INV TEXT SEARCH ERROR BOUNDARY', {
			error: this.state.error,
			componentStack: info.componentStack
		});
	}

	render() {
		return this.state.hasError ? null : this.props.children;
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};
