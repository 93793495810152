/**
 * Creates query strings for results. Used in href of results list and request to FFSS
 * User will not actually use this href unless they interact with the anchors in a way other than
 * normal point-and-click.
 *
 * @param {array} appliedFilters - List of objects containing filtered data (DDC.InvData.getAppliedFilters())
 * @returns {string}
 */
export const getFilterParams = (appliedFilters = []) => {
	if (appliedFilters.length) {
		const filterParams = appliedFilters.map((filter) =>
			filter.values.map((i) => `${filter.id}=${i.value}`)
		);
		return filterParams.join('&');
	}
	return '';
};

/**
 * Calls setFilterSearchEventData for the result clicked.
 *
 * @param event
 * @param facetInfo
 */
export const applyFilters = (
	event,
	facetInfo,
	contextEnabled,
	spellcheckEnabledFlag
) => {
	event.preventDefault();
	if (spellcheckEnabledFlag) {
		window.DDC.InvData.spellcheckSearchTerm(
			event,
			facetInfo,
			contextEnabled
		);
	} else if (facetInfo) {
		if (!contextEnabled) {
			window.DDC.InvData.clearFilters();
		}

		// Persisting geoZip and geoRadius
		const appliedFilters = window.DDC.InvData.getAppliedFilters();
		const geoData = appliedFilters
			.filter((key) => key.id.includes('geo'))
			.reduce((obj, item) => {
				return {
					...obj,
					[item.id]: item.values[0].value
				};
			}, {});

		const newFacetInfo = {
			...geoData,
			...facetInfo
		};

		Object.keys(newFacetInfo).forEach((name) => {
			window.DDC.InvData.setFilterSelections({
				filterName: name,
				filterValues: [newFacetInfo[name]],
				filterValue: newFacetInfo[name],
				element: event.target
			});
		});
	}
};
